import React from "react";
import { makeStyles } from "@styles";
import { useTranslation } from "@i18n";
import { Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "80%",
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  text: {
    marginTop: 10,
    color: "#000000",
    fontSize: 18,
    textAlign: "center",
    paddingBottom: 6,
  },
}));

const PageNotFound = () => {
  const classes: any = useStyles({});
  const { t } = useTranslation(["common"]);

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{t("common:errors.pageNotFound")}</Typography>
    </div>
  );
};

export default PageNotFound;
